import React, { useState } from "react"

const ScrollUp = () => {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  window.addEventListener("scroll", checkScrollTop)

  return (
    <a
      className=" fas fa-arrow-circle-up icon-size-md color-secondary float-right"
      onClick={scrollTop}
      style={{
        height: 40,
        display: showScroll ? "flex" : "none",
        cursor: "pointer",
      }}
    ></a>
  )
}

export default ScrollUp
