import React from "react"
import Newsletter from "./newsletter"
import Contact from "./contact"
import ScrollUp from "./scrollup"
const Footer = () => (
  <>
    <Newsletter />
    <Contact/>
    <div className="footer-bottom py-3 gray-light-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-lg-5">
            <div className="copyright-wrap small-text">
              <p className="mb-0 text-center">
                © DENTED Development Inc, All rights reserved
              </p>
            </div>
          </div>

          <div className="col-md-5 col-lg-5">
            <div className="terms-policy-wrap text-lg-right text-md-right text-left">
              <ul className="list-inline text-center">
                <li className="list-inline-item">
                  <a className="small-text" href="#">
                    Terms
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="small-text" href="#">
                    Security
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="small-text" href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-lg-2 ">
            <ScrollUp />
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Footer
