import React, { Component } from "react"

class Contact extends Component {

  render() {
    return (
        <section className="section contact-us-area  ptb-60" id="contact">
        <div className="container text-center">
          <div className="row mb-40">
            <div className="col-lg-12 col-md-12 col-sm-10 col-xs-12 col-xs-center">
              <h2 className="text-center col-md-12 col-sm-12">CONTACT US</h2>
              <p className="text-center">For more information or assistance in setting up InTow Connect, you can reach DENTED Development Inc at:</p>
            </div>
          </div>
          <div className="row mb-20">
            <div className="col-md-4 col-sm-12">
              <div className="t-center p-20">
                <div className="contact-icon">
                  <a href="tel:+18667062366">
                    <i className="fas fa-phone fa-2x" aria-hidden="true" />
                  </a>
                </div>
                <h3 className="mb-10">
                  <a href="tel:+18667062366">+1 (866) 706-2366</a>
                </h3>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="t-center p-20">
                <div className="contact-icon">
                  <a href="support@denteddev.com">
                    <i className="fas fa-envelope fa-2x" aria-hidden="true" />
                  </a>
                </div>
                <h3 className="mb-10">
                  <a href="mailto:support@denteddev.com">support@denteddev.com</a>
                </h3>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="t-center p-20">
                <div className="contact-icon">
                  <a href="tel:+15193199665">
                    <i className="fas fa-sms fa-2x" aria-hidden="true" />
                  </a>
                </div>
                <h3 className="mb-10">
                  <a href="tel:+15193199665">+1 (519) 319-9665</a>
                </h3>
              </div>
            </div>
          </div>

        </div>
      </section>
    )
  }
}

export default Contact
