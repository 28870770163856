import React, { Component } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

interface IProps {}

interface IState {
  email: string
  message: string
}

class Newsletter extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      email: "",
      message: "Subscribe our Newsletter",
    }
  }
  changeEmailHandler = event => {
    this.setState({ email: event.target.value })
  }
  handleSubmit = e => {
    e.preventDefault()
    this.setState({ message: "Thank You For Subscription!" })
    addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        console.log(data)
      })
      .catch(error => {
        console.log(error)
      })
    this.setState({ email: "" })
  }
  render() {
    return (
      <footer className="footer-1 gradient-bg ptb-60 footer-with-newsletter">
        <div className="container">
          <div className="row newsletter-wrap primary-bg rounded shadow-lg p-5">
            <div className="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
              <div className="newsletter-content text-white">
                <h3 className="mb-0 text-white mt-2">{this.state.message}</h3>
                {/* <p className="mb-0">
                  We’re a team of non-cynics who truly care for our work.x
                </p> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <form
                className="newsletter-form position-relative"
                onSubmit={this.handleSubmit}
              >
                <input
                  type="email"
                  value={this.state.email}
                  onChange={this.changeEmailHandler}
                  className="input-newsletter form-control"
                  placeholder="Enter your email"
                  name="email"
                  required
                  autoComplete="off"
                />
                <button type="submit" className="disabled">
                  <i className="fas fa-paper-plane"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Newsletter
